import { NgModule } from '@angular/core';
import { AuthEffects } from '@modules/auth/cognito-auth/store/effects';
import { authReducer } from '@modules/auth/cognito-auth/store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [StoreModule.forFeature('auth', authReducer), EffectsModule.forFeature([AuthEffects])],
  exports: [StoreModule, EffectsModule]
})
export class AuthStoreModule {}
