import { registerAction } from '@core';
import { UserDetailsModel } from '@core/models/auth/user-details.model';
import { createAction, props } from '@ngrx/store';

export class AuthActionTypes {
  static readonly loadUserDetails = registerAction('[Auth] Load User Details');
  static readonly loadUserDetailsSuccess = registerAction('[Auth] Load User Details Success');
  static readonly loadUserDetailsError = registerAction('[Auth] Load User Details Error');
}

export const loadUserDetails = createAction(
  AuthActionTypes.loadUserDetails,
  props<{ email: string }>()
);

export const loadUserDetailsSuccess = createAction(
  AuthActionTypes.loadUserDetailsSuccess,
  props<{ user: UserDetailsModel }>()
);

export const loadUserDetailsError = createAction(
  AuthActionTypes.loadUserDetailsError,
  props<{ error: string }>()
);
