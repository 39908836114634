import { Component } from '@angular/core';
import { IRIcon } from '@core/enums';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-selected-sdg-dialog',
  templateUrl: './selected-sdg-dialog.component.html',
  styleUrls: ['./selected-sdg-dialog.component.scss']
})
export class SelectedSdgDialogComponent {
  IRIcon = IRIcon;

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef) {}
}
