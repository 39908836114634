import { Component, Input } from '@angular/core';
import { IRIcon } from '@core/enums';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent {
  @Input() icon: IRIcon | undefined;

  getIconId(): string {
    return `#${this.icon}`;
  }

  getIconClass(): string {
    return `icon-${this.icon}`;
  }
}
