import { Component, Input } from '@angular/core';
import { IRIcon } from '@app/core/enums/ir-icon.enum';
import { TableSortModel } from '@app/core/models/sorting';

@Component({
  selector: 'app-user-roles-header',
  templateUrl: './user-roles-header.component.html',
  styleUrls: ['./user-roles-header.component.scss']
})
export class UserRolesHeaderComponent {
  IRIcon = IRIcon;
  @Input() sortModel: TableSortModel;
  @Input() headerName: string;
  @Input() fieldName: string;
}
