<div (click)="hiddenCount > 0 ? openSdgDialog() : null" class="sdg-chips-container">
  <div #sdgWrapper class="d-flex sdg-wrapper">
    <app-sdg-chip
      *ngFor="let sdg of sdgs.slice(0, numberOfElsThatCanFit)"
      [sdgType]="sdg"
    ></app-sdg-chip>
    <div *ngIf="hiddenCount > 0" class="hidden-count-wrapper">
      <span>+{{ hiddenCount }}</span>
    </div>
  </div>
</div>
