<div [ngClass]="{ 'nav-item': inNavbar }" ngbDropdown>
  <a *ngIf="inNavbar; else button" id="language-dropdown" class="nav-link" ngbDropdownToggle>
    {{ translateService.currentLang | translate }}
  </a>

  <ng-template #button>
    <button class="btn btn-sm btn-secondary" ngbDropdownToggle>
      {{ translateService.currentLang | translate }}
    </button>
  </ng-template>

  <div ngbDropdownMenu aria-labelledby="language-dropdown" [ngClass]="menuClass">
    <button
      class="dropdown-item"
      *ngFor="let language of getAvailableLanguages()"
      (click)="translateService.use(language)"
    >
      {{ language | translate }}
    </button>
  </div>
</div>
