<div *ngIf="type" class="sdg-button-container" [class.is-selected]="isSelected">
  <img
    class="sdg-image"
    [class.hidden]="isSelected"
    [src]="'/assets/images/sdg/E-WEB-Goal-' + type + '-unselected.svg'"
    [alt]="'sdg number ' + type"
  />
  <img
    class="sdg-image"
    [class.hidden]="!isSelected"
    [src]="'/assets/images/sdg/E-WEB-Goal-' + type + '.svg'"
    [alt]="'sdg number ' + type"
  />
  <img
    [class.hidden]="!isSelected || noCheck"
    class="selected-check-icon"
    src="/assets/icons/svg/check-circle.svg"
    [alt]="'sdg number' + type + 'checked-mark'"
  />
</div>
