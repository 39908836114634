import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';
import { IRIcon } from '@core/enums';
import { MockUserModel } from '@core/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-query-search-input',
  templateUrl: './query-search-input.component.html',
  styleUrls: ['./query-search-input.component.scss']
})
export class QuerySearchInputComponent implements OnInit {
  throttleTime = throttleTime;
  queryFormControl: FormControl = new FormControl('', [Validators.required]);
  mockValues: MockUserModel[] = [
    {
      id: '1',
      name: 'Eray Ismailov',
      groups: ['Astrology', 'Biology'],
      caption: 'Everything I Learned About Professor I Learned From Potus'
    },
    {
      id: '2',
      name: 'Ariel Segundo',
      groups: ['Astrology', 'Biology'],
      caption: "Genghis Khan's Guide To Professor Excellence"
    },
    {
      id: '3',
      name: 'Analytoy Nikulov',
      groups: ['Astrology', 'Biology'],
      caption: "Houdini's Guide To Professor"
    },
    {
      id: '4',
      name: 'Kremena Dobreva',
      groups: ['Astrology', 'Biology'],
      caption: "Joseph's Stalin's Secret Guide To Professor"
    },
    {
      id: '5',
      name: 'Stati Chobanov',
      groups: ['Astrology', 'Biology'],
      caption: 'Professor And The Mel Gibson Effect'
    },
    {
      id: '6',
      name: 'Emily Hill',
      groups: ['Astrology', 'Biology'],
      caption: "Sun Tzu's Awesome Tips On Professor"
    }
  ];
  filteredResults: MockUserModel[] = [...this.mockValues];
  IRIcon = IRIcon;
  inputIsFocused: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Output() userSelect: EventEmitter<MockUserModel> = new EventEmitter<MockUserModel>();

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.attachQueryListener();
    this.attachUserSelectListener();
  }

  // Hides the dropdown on Outside Click
  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    this.inputIsFocused.next(this.elementRef.nativeElement.contains(event.target));
  }

  attachUserSelectListener(): void {
    // 1. For a workaround for the issue where User List dropdown would close prematurely
    // - before we hit the click event on the dropdown list
    // 2.This line is needed for the dropdown to close after the Click Event
    this.userSelect.pipe(untilDestroyed(this)).subscribe(() => this.inputIsFocused.next(false));
  }

  // Fire filtering of the results on each query change
  attachQueryListener(): void {
    this.queryFormControl.valueChanges
      .pipe(untilDestroyed(this), debounceTime(50))
      .subscribe((searchQuery: string): void => {
        this.filteredResults = this.filterValues(searchQuery);
      });
  }

  filterValues(query: string): MockUserModel[] {
    return this.mockValues.filter((user: MockUserModel) =>
      // Returns true if any of the fields below includes the query
      [user.name, user.caption].some((field: string) => field.includes(query))
    );
  }
}
