import { WindowMessageTypeEnum } from '@core';
import { EnvironmentModel } from '@core/models/environment.model';

export const environment: EnvironmentModel = {
  name: 'DEV',
  windowMessageOpts: {
    allowedOrigins: [
      'http://localhost:4200',
      'https://stage-vvimpacttracker.com',
      'https://dev-vvimpacttracker.com'
    ],
    types: [WindowMessageTypeEnum.COGNITO_AUTH_TRANSFER]
  },
  production: false,
  apiUrl: '/itplus-api',
  cognitoAuth: {
    amplify: {
      Auth: {
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_rcz4cBzsw',
        userPoolWebClientId: '32b9aja4g0b9sj9kbpo09cr65b',
        mandatorySignIn: true,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        oauth: {
          domain: 'deviuk-user-pool.auth.eu-west-1.amazoncognito.com',
          scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'https://dev-trackimpact.org/auth/sign-in',
          redirectSignOut: 'https://dev-trackimpact.org',
          responseType: 'code'
        }
      }
    }
  }
};
