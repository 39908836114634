import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router, UrlTree } from '@angular/router';
import { APP_ROUTES } from '@core';
import { AuthService } from '@modules/auth/cognito-auth';

@Injectable()
/**
 * @description Prevents access to routes when users is already logged in
 * @redirectsTo APP_ROUTES.MY_REVIEWS
 * */
export class UnauthorizedGuard implements CanLoad, CanActivate, CanActivateChild {
  constructor(private auth: AuthService, private router: Router) {}

  canLoad(): boolean | UrlTree {
    return this.returnAuthorizedOrRedirect();
  }

  canActivate(): boolean | UrlTree {
    return this.returnAuthorizedOrRedirect();
  }

  canActivateChild(): boolean | UrlTree {
    return this.returnAuthorizedOrRedirect();
  }

  private returnAuthorizedOrRedirect(): boolean | UrlTree {
    if (this.auth.isLoggedIn) {
      return this.router.parseUrl(APP_ROUTES.MY_REVIEWS);
    }

    return true;
  }
}
