export enum IRIcon {
  ACTIVE_STATUS = 'active-status',
  ARROW_LEFT_WHITE = 'arrow-left-white',
  ARROW_LEFT = 'arrow-left',
  ARROW_RIGHT = 'arrow-right',
  CALENDAR_NEXT = 'calendar-next',
  CALENDAR_PREV = 'calendar-prev',
  CALENDAR = 'calendar',
  CARET_DOWN_2 = 'caret-down-2',
  CARET_DOWN = 'caret-down',
  CARET_UP_2 = 'caret-up-2',
  CARET_UP = 'caret-up',
  CHART_MONITOR = 'chart-monitor',
  CHAT_ALT = 'chat-alt',
  CHAT_MONITOR = 'chat-monitor',
  CHAT_WHITE = 'chat-white',
  CHAT_WRITING = 'chat-writing',
  CHAT = 'chat',
  CHECK_CIRCLE = 'check-circle',
  CHECK_CYAN = 'check-cyan',
  CHECK_GREEN = 'check-green',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_UP = 'chevron-up',
  CLOCK = 'clock',
  CLOSE_ALT = 'close-alt',
  CLOSE_SQUARE = 'close-square',
  CLOSE_X = 'close-x',
  CLOSE = 'close',
  COG = 'cog',
  COLLABORATION = 'collaboration',
  CONNECT_SIDEBAR = 'connect-sidebar',
  CONTRIBUTOR = 'contributor',
  COPY = 'copy',
  CREATE = 'create',
  DOCUMENTS = 'documents',
  DOWNLOAD = 'download',
  EDIT = 'edit',
  HELP_ICON = 'help-icon',
  ILLUSTRATION_STEP_1 = 'illustration-step-1',
  ILLUSTRATION_STEP_2 = 'illustration-step-2',
  ILLUSTRATION_STEP_4 = 'illustration-step-4',
  MODERATOR = 'moderator',
  MOVE = 'move',
  MY_REVIEWS = 'my-reviews',
  P_CALENDAR = 'p-calendar',
  PROJECT_BLANK = 'project-blank',
  RESEARCH_BIG = 'research-big',
  REVIEWER = 'reviewer',
  ROLES = 'roles',
  SORT = 'sort',
  STEP_MILESTONE = 'step-milestone',
  STEP_REVIEWER = 'step-reviewer',
  STEP_TEMPLATE = 'step-template',
  STEP_USERS = 'step-users',
  TEMPLATE_BIG = 'template-big',
  THE_LOGO_BIG = 'the-logo-big',
  USERADD = 'useradd'
}
