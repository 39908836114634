import { CanActivate, CanActivateChild, CanLoad, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { AUTH_ROUTES } from '@core/constants';
import { AuthService } from '@modules/auth/cognito-auth';

@Injectable()
/**
 * @description Prevents access of routes if user is not yet logged in
 * @redirectsTo AUTH_ROUTES.SIGN_IN
 * */
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(): boolean | UrlTree {
    return this.returnAuthOrRedirect();
  }

  canActivateChild(): boolean | UrlTree {
    return this.returnAuthOrRedirect();
  }

  canLoad(): boolean | UrlTree {
    return this.returnAuthOrRedirect();
  }

  private returnAuthOrRedirect(): boolean | UrlTree {
    if (this.auth.isLoggedIn) {
      return true;
    }

    return this.router.parseUrl(AUTH_ROUTES.SIGN_IN);
  }
}
