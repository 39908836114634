import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_ROUTES } from '@core/constants';
import { SignInModel } from '@core/models/auth';
import { AuthService } from '@modules/auth/cognito-auth';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {
  fb: FormBuilder = new FormBuilder();
  APP_ROUTES = APP_ROUTES;

  userFormGroup: FormGroup = this.fb.group({
    userName: this.fb.control('', [Validators.required, Validators.minLength(3)]),
    password: this.fb.control('', [Validators.required, Validators.minLength(5)])
  });

  constructor(public auth: AuthService) {}

  async signIn(): Promise<void> {
    const value: SignInModel = this.userFormGroup.value as SignInModel;

    return await this.auth.signIn(value);
  }
}
