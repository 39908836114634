import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

interface DateRangePipeArgModel {
  startDate: string;
  endDate: string;
  locale?: string;
}

@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {
  transform({
    startDate,
    endDate,
    locale = 'en-us' //TODO Update to use ngx-translate
  }: DateRangePipeArgModel): string {
    if (startDate && endDate) {
      const formattedStartDate = formatDate(startDate, 'longDate', locale);
      const formattedEndDate = formatDate(endDate, 'longDate', locale);

      return `${formattedStartDate} - ${formattedEndDate}`;
    } else if (startDate) {
      return formatDate(startDate, 'longDate', locale);
    } else if (endDate) {
      return formatDate(endDate, 'longDate', locale);
    }

    return '';
  }
}
