import { ActionReducerMap } from '@ngrx/store';

export interface IAppState {
  update: unknown; // Change this on first State Implementation
}

export const appReducers: ActionReducerMap<IAppState> = {
  // Change this on first State Implementation
  update: () => {
    return '';
  }
};
