import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Auth, AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { AuthGuard, UnauthorizedGuard } from '@core';
import { AuthService, cognitoAuthRoutes } from '@modules/auth/cognito-auth';
import { AuthStoreModule } from '@modules/auth/cognito-auth/store/auth-store.module';
import { SignInComponent, SsoComponent } from './components';
import { AuthInterceptor } from '@app/core/interceptors';

@NgModule({
  declarations: [SignInComponent, SsoComponent],
  imports: [
    AuthStoreModule,
    RouterModule.forChild(cognitoAuthRoutes),
    CommonModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthGuard,
    UnauthorizedGuard,
    AuthService,
    { provide: AuthClass, useValue: Auth },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class CognitoAuthModule {}
