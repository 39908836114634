<div class="d-flex mb-3">
  <h2 class="col-header my-0">{{ headerName | translate }}</h2>
  <div class="caret">
    <app-svg-icon
      *ngIf="
        (sortModel.sortvalue === 0 || sortModel.field !== fieldName) && fieldName !== 'actions'
      "
      [icon]="IRIcon.SORT"
    ></app-svg-icon>
    <app-svg-icon
      *ngIf="sortModel.sortvalue === 1 && sortModel.field === fieldName && fieldName !== 'actions'"
      [icon]="IRIcon.CARET_UP_2"
    ></app-svg-icon>
    <app-svg-icon
      *ngIf="sortModel.sortvalue === -1 && sortModel.field === fieldName && fieldName !== 'actions'"
      [icon]="IRIcon.CARET_DOWN_2"
    ></app-svg-icon>
  </div>
</div>
