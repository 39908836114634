import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WindowMessageTypeEnum } from '@core';
import { WindowMessageModel } from '@core/models/core';
import { environment } from '@env';

@Injectable()
export class WindowMessageService {
  private events: Observable<Event> = fromEvent(window, 'message');

  static messageEvent(ev: MessageEvent<WindowMessageModel>): boolean {
    return ev.type === 'message';
  }

  static allowedOrigin(ev: MessageEvent<WindowMessageModel>): boolean {
    const originAllowed = environment.windowMessageOpts.allowedOrigins.includes(ev.origin);
    if (!originAllowed) {
      console.warn(`Origin [${ev.origin}] is not allowed to send messages to this frame`);
    }

    return originAllowed;
  }

  static allowedTypes(ev: MessageEvent<WindowMessageModel>): boolean {
    const allow = environment.windowMessageOpts.types.includes(ev.data.type);
    if (!allow) {
      console.warn(`Type [${ev.data.type}] is not in allowed message types`);
    }

    return allow;
  }

  static withData(ev: MessageEvent<WindowMessageModel>): boolean {
    const allow = !!ev.data;

    if (!allow) {
      console.warn(`[Event Data] is Missing!`);
    }

    return allow;
  }

  static specificType(ev: MessageEvent<WindowMessageModel>, type: WindowMessageTypeEnum): boolean {
    return ev.data.type === type;
  }

  static toMessageEvent(ev: Event): MessageEvent<WindowMessageModel> {
    return ev as MessageEvent<WindowMessageModel>;
  }

  listenForItMessages(type: WindowMessageTypeEnum): Observable<MessageEvent<WindowMessageModel>> {
    return this.events.pipe(
      map(WindowMessageService.toMessageEvent),
      filter(WindowMessageService.withData),
      filter(WindowMessageService.messageEvent),
      filter(WindowMessageService.allowedOrigin),
      filter(WindowMessageService.allowedTypes),
      filter((ev: MessageEvent<WindowMessageModel>) => WindowMessageService.specificType(ev, type))
    );
  }
}
