import { Routes } from '@angular/router';
import { UnauthorizedGuard } from '@app/core/guards';
import { SignInComponent, SsoComponent } from './components';

export const cognitoAuthRoutes: Routes = [
  {
    path: 'auth',
    canActivate: [UnauthorizedGuard],
    canActivateChild: [UnauthorizedGuard],
    children: [
      {
        path: 'sso',
        component: SsoComponent
      },
      {
        path: 'sign-in',
        component: SignInComponent
      }
    ]
  }
];
