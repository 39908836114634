import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectedSdgDialogComponent } from '@shared/components/dialog';
import { SdgEnum } from '@core/enums';

@UntilDestroy()
@Component({
  selector: 'app-sdg-chips',
  templateUrl: './sdg-chips.component.html',
  styleUrls: ['./sdg-chips.component.scss']
})
export class SdgChipsComponent implements AfterViewInit {
  dialogRef: DynamicDialogRef;
  hiddenCount = 0;
  numberOfElsThatCanFit = 0;
  baseDialogOpts: DynamicDialogConfig = {
    dismissableMask: false,
    width: '520px',
    showHeader: false,
    closeOnEscape: true,
    transitionOptions: '200ms cubic-bezier(0.25, 0.8, 0.25, 1)',
    modal: true,
    closable: true
  };
  @ViewChild('sdgWrapper') divToTrackHeightChanges: ElementRef<HTMLDivElement>;

  private _sdgs: SdgEnum[];

  get sdgs(): SdgEnum[] {
    return [...this._sdgs].sort(this.sdgSort.bind(this));
  }

  @Input() set sdgs(value: SdgEnum[]) {
    this._sdgs = value;
  }

  constructor(private cd: ChangeDetectorRef, public dialogService: DialogService) {}

  ngAfterViewInit(): void {
    // We are listening to wrapper dimensions
    this.resizeObservable(this.divToTrackHeightChanges.nativeElement)
      .pipe(untilDestroyed(this), debounceTime(500))
      .subscribe((rect: DOMRectReadOnly) => {
        this.numberOfElsThatCanFit = this.calcAvailableItemCount(rect);
        this.hiddenCount = this.sdgs.length - this.numberOfElsThatCanFit;

        this.cd.detectChanges();
      });
  }

  sdgSort(a: SdgEnum, b: SdgEnum): number {
    return Number(a) - Number(b);
  }

  calcAvailableItemCount(rect: DOMRectReadOnly): number {
    // Rounded value of (rectWidth minus 35 ( +# span) divided by 55 (total SDG Chip Width))
    return Math.floor((rect.width - 35) / 55);
  }

  resizeObservable(domDivElement: HTMLDivElement): Observable<DOMRectReadOnly> {
    return new Observable((subscriber: Subscriber<DOMRectReadOnly>) => {
      const resizeObserver: ResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) =>
        entries.forEach((entry: ResizeObserverEntry) => subscriber.next(entry.contentRect))
      );

      resizeObserver.observe(domDivElement);

      return function unsubscribe() {
        resizeObserver.unobserve(domDivElement);
      };
    });
  }

  openSdgDialog(): void {
    this.dialogRef = this.dialogService.open(SelectedSdgDialogComponent, {
      ...this.baseDialogOpts,
      data: { sdgs: this.sdgs }
    });
  }
}
