import { Component } from '@angular/core';
import { APP_ROUTES, AUTH_ROUTES } from '@core';
import { AuthService } from '@modules/auth/cognito-auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  APP_ROUTES = APP_ROUTES;
  AUTH_ROUTES = AUTH_ROUTES;
  constructor(public auth: AuthService) {}
}
