import { NgModule } from '@angular/core';

import { environment } from '@env';
import { EffectsModule } from '@ngrx/effects';
import { MetaReducer, StoreModule } from '@ngrx/store';

import { appReducers } from '@store/state';

export const metaReducers: MetaReducer[] = environment.production ? [] : []; // [storeFreeze];

@NgModule({
  imports: [StoreModule.forRoot(appReducers, { metaReducers }), EffectsModule.forRoot([])],
  exports: [StoreModule, EffectsModule]
})
export class AppStoreModule {}
