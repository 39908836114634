import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserDetailsModel } from '@core/models/auth/user-details.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as AuthActionsTypes from '@modules//auth/cognito-auth/store/actions';

@Injectable()
export class AuthEffects {
  loadUserDetails$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActionsTypes.loadUserDetails),
      switchMap(() =>
        this.http.get(`${this.loadUserByEmailEndpoint}`).pipe(
          map((user: UserDetailsModel) => AuthActionsTypes.loadUserDetailsSuccess({ user })),
          catchError(error => {
            return of(AuthActionsTypes.loadUserDetailsError(error));
          })
        )
      )
    );
  });

  private BASE_PATH = 'user';

  private loadUserByEmailEndpoint = 'ir-get-user';

  constructor(private store: Store, private actions$: Actions, private http: HttpClient) {}
}
