import { Routes } from '@angular/router';
import { APP_ROUTES } from '@core/constants';
import { AuthGuard } from '@core/guards';

export const appModuleRoutes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('@modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'review',
    canLoad: [AuthGuard],
    loadChildren: () => import('@modules/review/review.module').then(m => m.ReviewModule)
  },
  // Fallback when no prior route is matched
  // Since the router works using regex it will return the first match of all routes
  // The wildcard route { ** } must be last one so it is used only when there is no match
  { path: '**', redirectTo: APP_ROUTES.MY_REVIEWS, pathMatch: 'full' }
];
