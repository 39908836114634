import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IRIcon } from '@core/enums';
import { PrimeNGConfig } from 'primeng/api';
import moment from 'moment';

export interface CalendarTemplateDateModel {
  year: number;
  month: number;
  day: number;
  disabled: boolean;
  today: boolean;
}

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {
  IRIcon = IRIcon;
  dateRangeControl: FormControl;

  @Input() dateRangeForm: FormGroup;

  get selectedStartPeriod(): string {
    const date: Date = this.dateRangeControl.value?.[0] || '';
    return date ? moment(date).format('MMM DD, YYYY') : ''; // Jun, 10, 2021
  }

  get selectedEndPeriod(): string {
    const date: Date = this.dateRangeControl.value?.[1] || '';
    return date ? moment(date).format('MMM DD, YYYY') : ''; // Jun, 10, 2021
  }

  constructor(private primeNgConfig: PrimeNGConfig) {
    this.primeNgConfig.setTranslation({
      dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S']
    });
  }

  ngOnInit(): void {
    this.dateRangeControl = this.dateRangeForm.get('reviewPeriod') as FormControl;
  }

  selectedClass(date: CalendarTemplateDateModel): string {
    let [first, between, last] = [false, false, false];

    if (this.dateRangeForm.value.reviewPeriod) {
      const [_startDate, _endDate] = this.dateRangeForm.value.reviewPeriod;
      const startDate = new Date(_startDate);
      const endDate = new Date(_endDate);
      const inputDate: Date = new Date(date.year, date.month, date.day);
      // We cannot compare objects for equality unless we want to check for ref equality
      // LAST PART { && !!endDate  } is needed for start date to be rounded when we have only selected startDate and yet to pick endDate
      first = inputDate.toISOString() === startDate.toISOString() && !!endDate;
      between =
        inputDate.toISOString() > startDate.toISOString() &&
        inputDate.toISOString() < endDate.toISOString();
      last = inputDate.toISOString() === endDate?.toISOString();

      if (first === true) {
        return 'first';
      } else if (between === true) {
        return 'date-between';
      } else if (last === true) {
        return 'last';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}
