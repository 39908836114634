import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateRange'
})
export class ReviewDateRangePipe implements PipeTransform {
  transform(reviewPeriod: string[]): string {
    const [startDate, endDate] = reviewPeriod;
    // if locale is added to the reviewPeriod object then we can remove this const
    const locale = 'en-us';

    if (startDate && endDate) {
      const formattedStartDate = formatDate(startDate, 'MM/dd/YYYY', locale);
      const formattedEndDate = formatDate(endDate, 'MM/dd/YYYY', locale);

      return `${formattedStartDate} - ${formattedEndDate}`;
    } else if (startDate) {
      return formatDate(startDate, 'MM/dd/YYYY', locale);
    } else if (endDate) {
      return formatDate(endDate, 'MM/dd/YYYY', locale);
    }
    return '';
  }
}
