import { CommonModule, KeyValuePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SdgChipComponent, SdgChipsComponent, UserAvatarComponent } from '@shared/components';
import { SelectedSdgDialogComponent } from '@shared/components/dialog';
import { QuerySearchInputComponent } from '@shared/components/inputs';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DragDropModule } from 'primeng/dragdrop';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef
} from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { CalendarModule } from 'primeng/calendar';
import {
  DateRangeComponent,
  LanguageSelectorComponent,
  SdgButtonComponent,
  SvgSpriteModule
} from './components';
import { UserRolesDatatableComponent, UserRolesHeaderComponent } from './components/datatables';
import {
  ConvertToBytesPipe,
  DateRangePipe,
  ProjectDateRangePipe,
  WithDefaultValuePipe,
  ReviewDateRangePipe
} from './pipes';
import { TableSortService } from './service/table-sort/table-sort.service';

@NgModule({
  declarations: [
    WithDefaultValuePipe,
    DateRangePipe,
    ConvertToBytesPipe,
    ProjectDateRangePipe,
    ReviewDateRangePipe,
    LanguageSelectorComponent,
    UserRolesDatatableComponent,
    UserRolesHeaderComponent,
    SdgButtonComponent,
    QuerySearchInputComponent,
    UserAvatarComponent,
    SdgChipsComponent,
    SdgChipComponent,
    DateRangeComponent,
    SelectedSdgDialogComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SvgSpriteModule,
    TranslateModule,
    TableModule,
    ButtonModule,
    DragDropModule,
    AutoCompleteModule,
    InputTextModule,
    VirtualScrollerModule,
    CalendarModule,
    DynamicDialogModule
  ],
  exports: [
    SvgSpriteModule,
    CommonModule,
    FormsModule,
    DynamicDialogModule,
    ReactiveFormsModule,
    WithDefaultValuePipe,
    DateRangePipe,
    ConvertToBytesPipe,
    ProjectDateRangePipe,
    LanguageSelectorComponent,
    SdgButtonComponent,
    UserRolesDatatableComponent,
    UserRolesHeaderComponent,
    QuerySearchInputComponent,
    DateRangeComponent,
    UserAvatarComponent
  ],
  providers: [DynamicDialogRef, DynamicDialogConfig, DialogService, KeyValuePipe, TableSortService]
})
export class SharedModule {}
