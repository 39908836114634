<div class="user-roles-datatable-container">
  <!--  ZERO ELEMENTS DIV-->
  <div class="no-users-header" *ngIf="!rows.length">
    <h3 class="text-capitalize">{{ headerColumnName | translate }}</h3>
  </div>

  <!--  TABLE-->
  <p-table
    id="user-roles-table"
    *ngIf="rows.length"
    [columns]="columns"
    [value]="rows"
    [customSort]="true"
    sortField="name"
    [sortOrder]="1"
    (sortFunction)="customSort($event)"
  >
    <!--    HEADER ROW-->
    <ng-template pTemplate="header">
      <tr class="text-capitalize">
        <th id="col-name" class="role col col-md-5" pSortableColumn="name">
          <app-user-roles-header
            [headerName]="headerColumnName"
            [sortModel]="sortDetails"
            [fieldName]="'name'"
          ></app-user-roles-header>
        </th>
        <th id="col-groups" class="groups text-capitalize" pSortableColumn="groups">
          <app-user-roles-header
            [headerName]="'groups'"
            [sortModel]="sortDetails"
            [fieldName]="'groups'"
          ></app-user-roles-header>
        </th>
        <th id="col-sdgs" class="assigned-sdgs" pSortableColumn="sdgs">
          <app-user-roles-header
            [headerName]="'Assigned SDGs'"
            [sortModel]="sortDetails"
            [fieldName]="'sdgs'"
          ></app-user-roles-header>
        </th>
        <th *ngIf="hasAction" id="col-actions" class="row-actions col-2">
          <app-user-roles-header
            [headerName]="'actions'"
            [sortModel]="sortDetails"
            [fieldName]="'actions'"
          ></app-user-roles-header>
        </th>
      </tr>
    </ng-template>

    <!--BODY ROWS-->
    <ng-template pTemplate="body" let-row>
      <tr class="user-role-row" pDraggable="user">
        <td class="table-data user-col d-flex align-items-center">
          <div class="d-flex align-items-center mx-0 mx-md-3">
            <app-user-avatar [user]="row.user"></app-user-avatar>
          </div>
        </td>
        <td class="table-data groups-col">{{ row.user.groups.join(", ") }}</td>
        <td class="table-data">
          <app-sdg-chips [sdgs]="row.sdgs"></app-sdg-chips>
        </td>
        <td *ngIf="hasAction" class="table-data actions d-flex flex-row">
          <div class="d-flex flex-grow-1 justify-content-around">
            <app-svg-icon (click)="editRow.emit(row)" [icon]="IRIcon.EDIT"></app-svg-icon>

            <div class="divider"></div>

            <app-svg-icon (click)="removeRow.emit(row)" [icon]="IRIcon.CLOSE_X"></app-svg-icon>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="missing-users-for-sdgs mt-4" *ngIf="missingSdgs?.length">
    <p>
      {{ missingUsersText | translate }}:
      <span *ngFor="let missingSdg of missingSdgs; let last = last">
        SDG - {{ missingSdg }}{{ !last ? ", " : "" }}
      </span>
    </p>
  </div>
</div>
