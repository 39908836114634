<div *ngIf="dateRangeControl" class="date-range-container col form-group">
  <div [formGroup]="dateRangeForm">
    <p-calendar
      id="input-review-date"
      [styleClass]="'template-THE-date'"
      formControlName="reviewPeriod"
      placeholder="{{ 'Select date' | translate }}"
      selectionMode="range"
      [readonlyInput]="false"
      dataType="string"
    >
      <!-- calendar input header -->
      <!-- <ng-template pTemplate="header">
        <div class="datepicker-header d-flex justify-content-between">
          <input
            class="header-input col-5"
            [disabled]="true"
            [placeholder]="selectedStartPeriod"
            type="text"
          />
          <div class="cal-line col-2"></div>
          <input
            class="header-input col-5"
            [disabled]="true"
            [placeholder]="selectedEndPeriod"
            type="text"
          />
        </div>
      </ng-template> -->

      <ng-template pTemplate="date" let-date>
        <div [ngClass]="selectedClass(date)">
          <span [ngClass]="selectedClass(date)">{{ date.day }}</span>
        </div>
      </ng-template>
    </p-calendar>
    <app-svg-icon class="pcalendar-icon" [icon]="IRIcon.P_CALENDAR"></app-svg-icon>
  </div>
</div>
