export const APP_ROUTES = {
  HOME: 'home',
  CREATE_REVIEW: 'review/create',
  MY_REVIEWS: 'review/my-reviews'
};

export const AUTH_ROUTES = {
  SIGN_IN: 'auth/sign-in',
  SSO: 'auth/sso'
};

export const EXTERNAL_URL = {
  VV_OFFICIAL: 'https://www.vertigoventures.com/register/trackimpact/',
  VV_ABOUT_US: 'https://www.vertigoventures.com/impact-experts/',
  VV_CONTACT_US: 'https://www.vertigoventures.com/contact/',
  VV_URL: 'http://www.vertigoventures.com/',
  TI_URL: 'https://trackimpact.org/',
  VV_TERMS: 'https://www.vertigoventures.com/terms-and-condition/',
  VV_TERMS_PDF:
    'https://f.hubspotusercontent20.net/hubfs/8102620/Website/Downloads/2021/VV%20Terms%20&%20Conditions.pdf',
  ALL_ABOUT_COOKIES: 'http://www.allaboutcookies.org/manage-cookies/',
  VV_MAILTO: 'mailto:hello@vertigoventures.com',
  GOOGLE_PARTNERS: 'https://www.google.com/policies/privacy/partners/',
  GOOGLE_ANALYTICS: 'https://tools.google.com/dlpage/gaoptout/',
  ICO_URL: 'https://ico.org.uk/concerns/',
  VV_POLICY: 'https://www.vertigoventures.com/privacy-policy/',
  VV_DEMO_FORM: 'https://www.vertigoventures.com/demo-form-impacttracker/',
  VV_IT_LOGIN: 'https://www.vertigoventures.com/products/impacttracker-impact-software/login/'
};
