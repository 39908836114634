import { Component, Input } from '@angular/core';
import { MockUserModel } from '@core/models';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent {
  // This Type is Subject to change that's why it is inline typed
  @Input() user: MockUserModel;

  // Display only Icon and Name
  @Input() basic: boolean;
}
