import { Component, Input } from '@angular/core';
import { SdgEnum } from '@core/enums';

@Component({
  selector: 'app-sdg-chip',
  templateUrl: './sdg-chip.component.html',
  styleUrls: ['./sdg-chip.component.scss']
})
export class SdgChipComponent {
  @Input() sdgType: SdgEnum;
}
