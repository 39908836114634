<div class="filter-search-input-container" (focusin)="inputIsFocused.next(true)">
  <span class="input-wrapper">
    <app-svg-icon class="pi" [icon]="IRIcon.USERADD"></app-svg-icon>
    <input
      type="text"
      pInputText
      [placeholder]="'Search or invite people and groups...' | translate"
      [formControl]="queryFormControl"
    />
  </span>

  <div class="results" [class.is-visible]="inputIsFocused | async">
    <div class="result-description">
      <h5 class="text-capitalize">{{ "found" | translate }}</h5>
      <div class="result-wrapper d-flex">
        <h1>{{ filteredResults?.length }}</h1>
        <p>{{ "People and groups for" | translate }} "{{ queryFormControl.value }}"</p>
      </div>
    </div>

    <!--    This User List is lazy loaded So we do not overflow the DOM by loading all of them -->
    <p-virtualScroller
      (focusout)="inputIsFocused.next(false)"
      [scrollHeight]="filteredResults.length < 4 ? filteredResults.length * 70 : 300"
      [minBufferPx]="500"
      [maxBufferPx]="1000"
      [value]="filteredResults"
      [itemSize]="70"
    >
      <ng-template let-user pTemplate="item">
        <app-user-avatar (click)="userSelect.emit(user)" [user]="user"></app-user-avatar>
      </ng-template>
    </p-virtualScroller>
  </div>
</div>
