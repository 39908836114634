<main
  class="
    h-100
    pb-3
    d-flex
    flex-grow-1
    container-fluid
    flex-column flex-sm-row
    overflow-auto
    shell-gradient
    justify-content-center
  "
>
  <div class="flex-grow-sm-1 flex-grow-0">
    <div id="dev-panel">
      <p class="m-0">DEV PANEL</p>
      <button class="btn primary sm" (click)="auth.signOut()">Log Out</button>
      <button class="btn primary sm" [routerLink]="APP_ROUTES.HOME">HOME</button>
      <button
        class="btn primary sm"
        [disabled]="auth.isLoggedIn"
        [routerLink]="AUTH_ROUTES.SIGN_IN"
      >
        SIGN_IN
      </button>
      <button class="btn primary sm" [disabled]="auth.isLoggedIn" [routerLink]="AUTH_ROUTES.SSO">
        SSO
      </button>
      <button
        class="btn primary sm"
        [disabled]="!auth.isLoggedIn"
        [routerLink]="APP_ROUTES.MY_REVIEWS"
      >
        MY_REVIEWS
      </button>
      <button
        class="btn primary sm"
        [disabled]="!auth.isLoggedIn"
        [routerLink]="APP_ROUTES.CREATE_REVIEW"
      >
        CREATE_REVIEW
      </button>
    </div>
    <div class="col overflow-auto h-100 main-max-width">
      <div class="bg-light rounded-3">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</main>

<app-svg-sprite-def class="d-none"></app-svg-sprite-def>
