import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IRIcon } from '@app/core/enums/ir-icon.enum';
import { TableSortModel } from '@app/core/models/sorting';
import { TableSortService } from '@app/shared/service/table-sort/table-sort.service';
import { SdgEnum } from '@core/enums';
import { TableUserRoleModel } from '@core/models';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-user-roles-datatable',
  templateUrl: './user-roles-datatable.component.html',
  styleUrls: ['./user-roles-datatable.component.scss']
})
export class UserRolesDatatableComponent {
  IRIcon = IRIcon;
  sortDetails: TableSortModel = {
    event: {},
    sortvalue: 0,
    field: '',
    orderResult: 0
  };

  @Input() headerColumnName: string;
  @Input() missingUsersText: string;
  @Input() columns: { field: string; header: string }[];
  @Input() rows: TableUserRoleModel[];
  @Input() missingSdgs: SdgEnum[];
  @Input() hasAction = true;

  @Output() removeRow: EventEmitter<TableUserRoleModel> = new EventEmitter<TableUserRoleModel>();
  @Output() editRow: EventEmitter<TableUserRoleModel> = new EventEmitter<TableUserRoleModel>();

  constructor(private sortService: TableSortService) {}

  customSort(event: SortEvent): void {
    this.sortDetails.event = event;
    this.sortDetails = this.sortService.sortTable(this.sortDetails);
  }
}
