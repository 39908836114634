import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

interface ProjectDateRangePipeArgModel {
  startDate: string;
  endDate: string;
  dateAdded: string;
  locale?: string;
}

@Pipe({
  name: 'projectDateRange'
})
export class ProjectDateRangePipe implements PipeTransform {
  transform({
    startDate,
    endDate,
    dateAdded,
    locale = 'en-us' //TODO Update to use ngx-translate
  }: ProjectDateRangePipeArgModel): string {
    if (startDate && endDate) {
      const formattedStartDate = formatDate(startDate, 'mediumDate', locale);
      const formattedEndDate = formatDate(endDate, 'mediumDate', locale);

      return `${formattedStartDate} to ${formattedEndDate}`;
    } else if (startDate) {
      const formattedStartDate = formatDate(startDate, 'mediumDate', locale);

      return `${formattedStartDate} to -`;
    } else if (endDate) {
      const formattedDateAdded = formatDate(dateAdded, 'mediumDate', locale);
      const formattedEndDate = formatDate(endDate, 'mediumDate', locale);

      return `${formattedDateAdded} to ${formattedEndDate}`;
    }

    return '';
  }
}
