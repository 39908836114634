<div class="selected-sdg-dialog-container p-5">
  <div class="header-wrapper d-flex mb-5">
    <div class="header-icon-wrapper">
      <app-svg-icon [icon]="IRIcon.USERADD"></app-svg-icon>
    </div>
    <p class="header-text m-0">{{ "Assigned SDGs" | translate }}</p>
  </div>

  <div *ngIf="config.data?.sdgs" class="body-wrapper d-flex flex-row flex-wrap mb-5">
    <app-sdg-chip [sdgType]="sdg" *ngFor="let sdg of config.data.sdgs"></app-sdg-chip>
  </div>

  <div class="buttons-wrapper d-flex justify-content-end">
    <button (click)="ref.close(null)" class="btn primary">{{ "ok" | translate }}</button>
  </div>
</div>
