import { Injectable } from '@angular/core';
import { TableSortModel } from '@app/core/models/sorting';

@Injectable()
export class TableSortService {
  sortTable(sortDetails: TableSortModel): TableSortModel {
    let order = 0;
    if (sortDetails.event.order) {
      order = sortDetails.event.order;
    }

    if (sortDetails.event.data) {
      sortDetails.event.data.sort((data1, data2) => {
        sortDetails.field = sortDetails.event.field || '';
        let value1;
        let value2;

        if (sortDetails.field == 'sdgs') {
          value1 = data1[sortDetails.field].length;
          value2 = data2[sortDetails.field].length;
        } else {
          value1 = data1['user'][sortDetails.field];
          value2 = data2['user'][sortDetails.field];
        }

        let result = null;
        if (!value1 && value2 !== null) {
          result = -1;
        } else if (value1 !== null && !value2) {
          result = 1;
        } else if (!value1 && !value2) {
          result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        } else {
          result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        }

        sortDetails.sortvalue = order;
        sortDetails.orderResult = order * result;

        return sortDetails.orderResult;
      });
    }

    return sortDetails;
  }
}
