import { createReducer, on } from '@ngrx/store';
import { AuthStoreState } from '@modules/auth/cognito-auth/store/state';
import * as AuthActionsTypes from '@modules//auth/cognito-auth/store/actions';

export const initialState: AuthStoreState = {
  userDetails: {},
  error: ''
};

export const authReducer = createReducer<AuthStoreState>(
  initialState,
  on(AuthActionsTypes.loadUserDetailsSuccess, (state, { user: userDetails }) => ({
    ...state,
    userDetails
  })),
  on(AuthActionsTypes.loadUserDetailsError, (state, { error }) => ({
    ...state,
    error
  }))
);
