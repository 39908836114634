import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { loadUserDetails } from '@modules/auth/cognito-auth/store/actions';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { WindowMessageService } from '@core/services';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { AuthOptions, SignOutOpts } from '@aws-amplify/auth/lib-esm/types/Auth';
import { CustomCognitoUserModel, SignInModel, UserModel } from '@core/models/auth';
import { WindowMessageModel } from '@core/models/core';
import { WindowMessageTypeEnum } from '@core/enums';
import { environment } from '@env';
import { APP_ROUTES } from '@core';

@Injectable()
export class AuthService {
  logInMessageSubscription: Subscription;

  private authOpts: AuthOptions;
  private _cognitoUser: BehaviorSubject<CustomCognitoUserModel | null> =
    new BehaviorSubject<CustomCognitoUserModel | null>(null);

  get cognitoUser(): CustomCognitoUserModel | null {
    return this._cognitoUser.value;
  }

  set cognitoUser(value: CustomCognitoUserModel | null) {
    this._cognitoUser.next(value);

    if (this.user) {
      this.store.dispatch(loadUserDetails({ email: this.user.email }));
    }
  }

  get user(): UserModel | null {
    if (!this.cognitoUser) {
      return null;
    }

    const { name: firstName, family_name: lastName, email, sub: id } = this.cognitoUser.attributes;

    return { firstName, lastName, email, id };
  }

  /** @description User JWT Token */
  get idToken(): string | undefined {
    return this.cognitoUser?.getSignInUserSession()?.getIdToken().getJwtToken();
  }

  get isLoggedIn(): boolean {
    return !!this.cognitoUser;
  }

  constructor(
    private store: Store,
    private awsAmplify: AuthClass,
    private winMessageServ: WindowMessageService,
    private router: Router
  ) {
    this.authOpts = this.awsAmplify.configure(environment.cognitoAuth.amplify);

    this.loadExistingAuthSession();

    this.logInMessageSubscription = this.itSSOSubscription();
  }

  /** Subscription to messages of type COGNITO_AUTH_TRANSFER */
  itSSOSubscription(): Subscription {
    const ofAuthType: WindowMessageTypeEnum.COGNITO_AUTH_TRANSFER =
      WindowMessageTypeEnum.COGNITO_AUTH_TRANSFER;

    const authMessages: Observable<MessageEvent<WindowMessageModel>> =
      this.winMessageServ.listenForItMessages(ofAuthType);

    return authMessages.subscribe(this.logInWithMessage.bind(this));
  }

  /** Loads the already logged in user from localStorage */
  async loadExistingAuthSession(): Promise<void> {
    try {
      this.cognitoUser = await this.awsAmplify.currentAuthenticatedUser();
    } catch (e) {
      return;
    }
  }

  async signIn({ userName, password }: SignInModel): Promise<void> {
    const logInResult: CustomCognitoUserModel = await this.awsAmplify.signIn(userName, password);

    this.removeWinMessageSubscription();
    this.cognitoUser = logInResult;
  }

  async signOut(): Promise<void> {
    const opts: SignOutOpts = {
      // By setting this to true, you sign out users from all devices.
      // It also invalidates all refresh tokens issued to a cognitoUser.
      // The cognitoUser's current access and Id tokens remain valid until their expiry.
      // Access and Id tokens expire one hour after they are issued.
      global: false
    };

    try {
      await this.awsAmplify.signOut(opts);

      this.cognitoUser = null;
    } catch (error) {
      console.warn('Error Logging out', error);
    }
  }

  async logInWithMessage(message: MessageEvent<WindowMessageModel>): Promise<void> {
    try {
      const { userName, password } = JSON.parse(message.data.data);

      if (userName && password) {
        await this.signIn({ userName, password });
        await this.router.navigate([APP_ROUTES.MY_REVIEWS]);
      } else {
        console.warn('Missing User Credentials in DATA Object');
      }
    } catch (e) {
      console.error(e);
    }
  }

  removeWinMessageSubscription(): void {
    return this.logInMessageSubscription.unsubscribe();
  }
}
