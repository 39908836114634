import { HttpClient } from '@angular/common/http';
import { Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { WindowMessageService } from '@core/services';
import { CoreModule } from '@core';
import { LanguageEnum } from '@core/enums';
import { environment } from '@env';
import { CognitoAuthModule, AuthService } from '@modules/auth/cognito-auth';
import { HomeModule } from '@modules/home/home.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { httpTranslationLoader } from '@shared/classes/http-translate-loader';
import { SharedModule } from '@shared/shared.module';
import { TabViewModule } from 'primeng/tabview';
import { AppStoreModule } from './app-store.module';
import { AppComponent } from './app.component';
import { appModuleRoutes } from './app.routing';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(appModuleRoutes, { enableTracing: false }),
    CoreModule,
    SharedModule,
    BrowserModule,
    CognitoAuthModule,
    HomeModule,
    AppStoreModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    TranslateModule.forRoot({
      defaultLanguage: LanguageEnum.EN_GB,
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslationLoader,
        deps: [HttpClient]
      }
    }),
    TabViewModule
  ],
  declarations: [AppComponent],
  providers: [WindowMessageService],
  exports: [TranslateModule, RouterModule],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(AuthService) private auth: AuthService,
    @Inject(TranslateService) private translateService: TranslateService
  ) {
    this.translateService.addLangs(Object.values(LanguageEnum));
    this.translateService.use(LanguageEnum.EN_GB);
  }
}
